<template>
  <div class="section section-shaped section-zero min-vh-100 d-flex justify-content-center align-items-center">
    <div class="shape shape-style-1 bg-gradient-lighter">
    </div>
    <div class="row justify-content-center container" :key="componentKey">
      <div class="col-12 col-lg-6 container-main shadow" v-if="type_active !== 'not-have-permission'">
        <template v-if="type_active === 'login'">
          <span class="title"> {{ $t('ct-sign-in') }} </span>
          <login @change="changeType"></login>
          <button class="btn btn-secondary text-center" v-if="!config.hide_register_btn"
                  @click.prevent="changeType('register')">{{ $t('ct-create-account') }}
          </button>
          <a href="#" class="d-block text-center mt-2 helper-forgot-password"
             @click.prevent="changeType('remember')">{{ $t('ct-forgot-password') }}</a>
        </template>

        <template v-if="type_active === 'register'">
          <span class="title"> {{ $t('ct-register') }} </span>
          <register :areas_register="areas_register" @change="changeType"></register>
          <a href="#" class="helper-forgot-password text-center d-block mt-2"
             @click.prevent="changeType('login')">{{ $t('ct-sign-in-account') }}</a>
        </template>

        <template v-if="type_active === 'login-ba'">
          <span class="title">{{ $t('ct-access-ba-credentials') }}</span>
          <login-builderall :areas_register="areas_register" @change="changeType"></login-builderall>
        </template>

        <template v-if="type_active === 'remember'">
          <span class="title">{{ $t('ct-forgot-password') }}</span>
          <remember-password @change="changeType"></remember-password>

          <div class="mx-5 mt-2 mb-2 d-flex">
            <div class="text-center flex-grow-1">
              <template v-if="!config.hide_register_btn">
                <a href="#" class="helper-forgot-password"
                   @click.prevent="changeType('register')">{{ $t('ct-register') }}</a>
                <span class="mx-1 helper-forgot-password">|</span>
              </template>
              <a href="#" class="helper-forgot-password" @click.prevent="changeType('login')">{{ $t('ct-sign-in') }}</a>
            </div>
          </div>
        </template>

        <template v-if="type_active === 'login-token'">
          <span class="title">{{ $t('ct-access-with-token') }}</span>
          <login-token @change="changeType"></login-token>
        </template>

      </div>

      <div class="col-12 col-lg-6" v-else-if="type_active === 'not-have-permission'">
        <not-have-permission></not-have-permission>
      </div>

    </div>
  </div>
</template>

<script>

    import {mixinRestrictAreaConfig, mixinRestrictAreaForm} from "@/js/mixins/restrictAreaForm";

    export default {
        name: "InBlockPage",
        mixins: [mixinRestrictAreaConfig, mixinRestrictAreaForm],
    }
</script>

<style lang="scss">
.modal-theme-6 {
  padding: 15px;
  background-color: #E6E6E6;

  .title {
    margin: 25px 0;
    display: flex;
    justify-content: center;
    font-size: 24px;
    /*font-weight: bold;*/
    color: #1A212F;
  }

  label {
    float: left;
  }

  .form-group {
    padding-top: 5px;
    margin-bottom: 0px;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 65px;
    border: none;
    border-radius: 0;
    background: #FFFFFF;
    transition: .3s ease;
    color: #555;
  }

  .btn {
    border-radius: 0px;
    width: 100%;
    height: 60px;
    font-size: 20px;
    /*font-weight: bold;*/
    background: none;
    color: #63666C;
    border: 1px solid #9B9B9B;
    box-shadow: none;
    margin-top: 5px;
    /*margin-bottom: 10px;*/

    &:hover {
      background: #F15D0D;
      color: #FFF;
      border: none;
    }
  }
}
</style>
